<template>
    <div>
        <navbar/>
        <image-implant/>
        <implant-composant class="mt100"/>
        <implant-passeport class="mt100"/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
import navbar from '@/components/NavBar.vue'
import ImplantComposant from '../components/ImplantComposant.vue';
import ImplantPasseport from '../components/ImplantsPasseport.vue';
import imageImplant from '../components/ImplantImage.vue';
import BulleContact from '../components/BulleContact.vue';
export default {
    components:{
        foot,
        navbar,
        imageImplant,
        ImplantPasseport,
        ImplantComposant,
        BulleContact
    },
    metaInfo: {
        title: 'MiceTracking - Implant  pour le Suivi de la Santé et du Bien-Être Animal',
        meta: [
            {
                name: 'description',
                content: 'Découvrez l’implant  MiceTracking, une solution innovante pour mesurer en temps réel la température, la pression artérielle, le rythme cardiaque et bien plus. Suivez l\'état de santé et le bien-être de vos souris de laboratoire en laboratoire avec précision.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, implant, suivi de la santé animale, bien-être animal, température corporelle, pression artérielle, rythme cardiaque, laboratoire, capteurs'
            }
        ]
    }

}
</script>


/* Animation CSS d'un chat qui marche de profil sur place */

<style>

</style>
