<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="10" lg="10" xl="8">
                <div class="implant-container">
                    <!-- <h2 class="title-product">Nos Implants</h2> -->
                    <div class="list-container">
                        <ul class="row-around item-implant">
                            <li :class="{ 'active': button == 1, 'inactive': button != 1 }">
                                <button @click="button = 1"><h3>Activité</h3></button>
                                <div :class="{ 'active': button == 1 }"></div>
                                <p>Température et Comportement</p>
                            </li>
                            <li :class="{ 'active': button == 3, 'inactive': button != 3 }">
                                <button @click="button = 3"><h3>PPG</h3></button>
                                <div :class="{ 'active': button == 3 }"></div>
                                <p>Santé et Comportement</p>
                            </li>
                            <li :class="{ 'active': button == 4, 'inactive': button != 4 }">
                                <button @click="button = 4"><h3>Laboratoire</h3></button>
                                <div :class="{ 'active': button == 4 }"></div>
                                <p>Santé, Comportement et Spectroscopie</p>
                            </li>
                        </ul>
                    </div>
                    <div class="implant-container-inferior row-around">
                        <div class="part-1">
                            <div>
                                <h4 class="tilte-part">Capteurs</h4>
                                <ul class="list-capteurs display-xl background-capteurs">
                                    <li v-show="true" :class="{ 'activeico': true }">
                                        <img src="../assets/ico/temp.png" alt="">
                                        <p>Température</p>
                                    </li>
                                    <li v-show="true" :class="{ 'activeico': true }">
                                        <img src="../assets/ico/acc.png" alt="">
                                        <p>Accéléromètre </p>
                                    </li>
                                    <li v-show="true" :class="{ 'activeico': true }">
                                        <img src="../assets/ico/Gyro.png" alt="">
                                        <p>Gyroscope </p>
                                    </li>
                                    
                                    <li  :class="{ 'activeico': button == 3 || button == 4 }">
                                        <img src="../assets/ico/ppg.png" alt="">
                                        <p>PPG</p>
                                    </li>
                                    <li :class="{ 'activeico': button == 4 }">
                                        <img src="../assets/ico/Spectrometrie.png" alt="">
                                        <p>Spectroscopie</p>
                                    </li>
                                </ul>
                                <div class="display-xs background-capteurs">
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/temp.png" alt="">
                                            <p>Température</p>
                                        </li>
                                        <li v-show="true" :class="{ 'activeico': true }">
                                            <img src="../assets/ico/acc.png" alt="">
                                            <p>Accéléromètre </p>
                                        </li>
                                        <li v-show="true" :class="{ 'activeico': true }">
                                            <img src="../assets/ico/Gyro.png" alt="">
                                            <p>Gyroscope </p>
                                        </li>
                                    </ul>
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': button == 3 || button == 4 }">
                                            <img src="../assets/ico/ppg.png" alt="">
                                            <p>PPG</p>
                                        </li>
                                        <li :class="{ 'activeico': button == 4 }">
                                            <img src="../assets/ico/Spectrometrie.png" alt="">
                                            <p>Spectroscopie</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="spectroscopie" :class="{ 'activespectro': button == 3 || button == 4 }">
                                <h4 class="tilte-part">LED</h4>
                                <ul class="list-spectroscopie row-around" :class="{ 'activsmall': button == 3 }">
                                    <li :class="{ 'activespectro': button == 4 }">
                                            <button id="delta1"></button>
                                            <p>uva</p>
                                            <p>405 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 4 }">
                                        <button id="delta2"></button>
                                        <p>bleue</p>
                                        <p>467 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 4 }">
                                        <button id="delta3"></button>
                                        <p>verte</p>
                                        <p>571 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 3 || button == 4 }">
                                        <button id="delta4"></button>
                                        <p>rouge</p>
                                        <p>626 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 3 || button == 4 }">
                                        <button id="delta5"></button>
                                        <p>infrarouge</p>
                                        <p>880 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 4 }">
                                        <button id="delta6"></button>
                                        <p>infrarouge</p>
                                        <p>940 nm</p>
                                    </li>
                                    <li :class="{ 'activespectro': button == 4 }">
                                        <button id="delta7"></button>
                                        <p>infrarouge</p>
                                        <p>1100 nm</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="part-1">
                            <div class="image-implant-container">
                                <img src="../images/ImplantSeul.png" alt="">
                            </div>
                        </div>
                        <div class="part-2 ">
                            <div class="capteurs">
                                <h4 class="tilte-part">Données</h4>
                                <div class="row-around background-capteurs margin5">
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/mémoire.png" alt="" >
                                            <p>Passeport intégré</p>
                                        </li>
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/tremblements.png" alt="" >
                                            <p>Tremblements</p>
                                        </li>
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/mouv.png" alt="" >
                                            <p>Comportement</p>
                                        </li>
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/sommeil.png" alt="" >
                                            <p>Sommeil</p>
                                        </li>
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/reproduction.png" alt="" >
                                            <p>Intéraction</p>
                                        </li>
                                    </ul>
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': button == 2 || button == 3 || button == 4 }">
                                            <img src="../assets/ico/coeur.png" alt="">
                                            <p>Rythme cardiaque</p>
                                        </li>
                                        <li :class="{ 'activeico': button == 3 || button == 4 }">
                                            <img src="../assets/ico/o2.png" alt="" >
                                            <p>Taux d'oxygène</p>
                                        </li>
                                        <li :class="{ 'activeico': button == 3 || button == 4 }">
                                            <img src="../assets/ico/pa.png" alt="" >
                                            <p>Tension artérielle</p>
                                        </li>
                                        <li :class="{ 'activeico': button == 4 }">
                                            <img src="../assets/ico/AnalyseSang.png" alt="">
                                            <p>Analyse Chimique</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="description">
                                <div class="information-implant" :class="{ 'active': button == 1 }">
                                    <h2 class="title-implant">{{implant[0].title}}</h2>
                                    <p>{{implant[0].dimension}}</p>
                                    <p>{{implant[0].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 2 }">
                                    <h2 class="title-implant">{{implant[1].title}}</h2>
                                    <p>{{implant[1].dimension}}</p>
                                    <p>{{implant[1].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 3 }">
                                    <h2 class="title-implant">{{implant[2].title}}</h2>
                                    <p>{{implant[2].dimension}}</p>
                                    <p>{{implant[2].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 4 }">
                                    <h2 class="title-implant">{{implant[3].title}}</h2>
                                    <p>{{implant[3].dimension}}</p>
                                    <p>{{implant[3].texte}}</p>
                                </div>
                                <div class="button-position">
                                    <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            button : 4,
            implant:[
                {title : 'MTS Activité', dimension : '( 155 x 20 mm )', texte : 'Notre implant MTS Activité est un microdispositif conçu pour surveiller en temps réel l\'activité, la température et le comportement des souris. Équipé de capteurs de température, d\'un gyroscope et d\'un accéléromètre, il permet de collecter des données précieuses telles que les tremblements, le sommeil, les interactions sociales et plus encore.'},
                {title : 'MTS ECG', dimension : '( 180 x 20 mm )', texte : 'Le MTS ECG est un implant innovant conçu pour mesurer en temps réel l\'activité électrique cardiaque des souris de laboratoire. Grâce à ses capteurs intégrés de température, de gyroscope, d\'accéléromètre et de ECG, il permet une analyse détaillée et précise du rythme cardiaque, tout en surveillant d\'autres aspects comportementaux comme les tremblements, le sommeil, et les interactions sociales.'},
                {title : 'MTS PPG', dimension : '( 195 x 20 mm )', texte : 'Doté de capteurs de température, d\'un gyroscope, d\'un accéléromètre, ainsi que de la technologie PPG (photopléthysmographie), Le MTS PPG permet de collecter des données précises sur la tension artérielle, le taux d\'oxygène, et l\'activité comportementale des sujets. Compact, le MTS PPG est une solution idéale pour les chercheurs qui souhaitent obtenir des données physiologiques et comportementales complètes.'},
                {title : 'MTS Laboratoire', dimension : '( 195 x 20 mm )', texte : 'Le MTS Spectroscopie est un implant polyvalent qui permet de mesurer en temps réel des paramètres vitaux tels que le rythme cardiaque, la tension artérielle, le taux d\'oxygène, et réalise des analyses chimiques précises grâce à la spectroscopie et un système LED multi-spectre. En plus de ces mesures physiologiques, il suit les tremblements, le sommeil, et le comportement des souris.'}
            ]
        }
    }
}
</script>

<style scoped>

ul{
    padding-left: 0px  !important;
}

.implant-container{
    border-radius: 25px;
    background-color: var(--white);
    /* border: solid 1px var(--noirdoux); */
    padding: 20px;
    width: fit-content;
}

.list-container {
    margin-top: 25px;
    position: sticky;
    top: 0px;
    background-color: var(--white);
    z-index: 1000;
}

.item-implant li{
    text-align: center;
}

.item-implant button:hover{
    opacity: 0.7;
}

.item-implant li div{
    width: 0px;
    height: 3px;
    background-color: black;
    transition: width 0.5s ease; /* Animation sur la hauteur */
}

.item-implant li div.active {
  width: 100%; /* Hauteur finale lors de l'animation */
}

.implant-container-inferior{
    margin-top: 55px;
}
.part-1{
    width: 30%;
}

.part-2{
    width: 40%;
}

.tilte-part{
    margin-bottom: 15px !important;
}

.image-implant-container {
    position: relative;
    text-align: center;
    overflow: hidden;
}


.image-implant-container img {
    position: relative;
    z-index: 2;
    border-radius: 50%;
    width: 100%;
}

.spectroscopie {
    width: 80%;
    margin-top: 60px;
    opacity: 0;
    transform: translateX(20px); /* Positionne les éléments légèrement hors de l'écran à gauche */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.spectroscopie.activespectro {
    opacity: 1;
    transform: translateX(0px);
}

.spectroscopie h4 {
    text-align: center;
    margin-bottom: 25px;
}

.list-spectroscopie {
    position: relative;
}

.list-spectroscopie.activsmall{
    width: 50px;
    left: 45%;
}

.list-spectroscopie li {
    position: relative;
    display: inline-block; /* Garde les boutons alignés horizontalement */
    margin-right: 15px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    display: none;
}

.list-spectroscopie li.activespectro {
    position: relative;
    display: inline-block; /* Garde les boutons alignés horizontalement */
    margin-right: 15px;
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}


.list-spectroscopie li button {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    opacity: 0.7;
    filter: blur(3px);
    position: relative;
    z-index: 1;
    transition: 0.5s;
}

.list-spectroscopie li button:hover{
    filter: blur(0px);
}

/* Par défaut, cacher les paragraphes */
.list-spectroscopie li p {
    display: none;
    position: absolute;
    top: 30px; /* Positionne le texte en dessous du bouton */
    left: 50%;
    transform: translateX(-50%); /* Centre le texte horizontalement */
    white-space: nowrap; /* Empêche le texte de se casser */
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    z-index: 2;
    opacity: 0;
    transition: 1s;

}

.list-spectroscopie li p + p {
    margin-top: 20px;
}
/* Afficher les paragraphes au survol du bouton */
.list-spectroscopie li button:hover + p,
.list-spectroscopie li button:hover + p + p {
    display: block;
    opacity: 1;
}

.list-capteurs{
    padding-left: 0px !important;
    width: 75%;
}

.background-capteurs{
    /* background-color: var(--background); */
    border-radius: 20px;
    /* border: solid 1px var(--noirdoux); */
    width: 100%;
    min-width: fit-content;
    padding: 20px;
}

.button-position{
    display: flex; 
    justify-content: right;
    margin-top: 50px;
}

.list-capteurs li {
    display: flex;
    justify-content: left;
    align-items: center;
    opacity: 0;
    transform: translateX(-20px); /* Positionne les éléments légèrement hors de l'écran à gauche */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-capteurs li.activeico {
    opacity: 1;
    transform: translateX(0);
}

.list-capteurs li img{
    width: 62px;
    height: 50px;
}

.list-capteurs li p{
    margin-top: 16px;
    margin-left: 10px;
    font-family: 'roboto', light;
    font-size: 16px;
}

.information-implant {
    margin-top: 30px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    display: none;
}

.information-implant.active {
    display: block;
}

.display-xl{
    display: block;
}
.display-xs{
    display: none;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 960px){
    .implant-container{
        border: none;
        padding: 0 0 50px 0;
    }
    .implant-container-inferior{
        margin-top: 50px;
    }
    .list-container{
        padding: 20px 0;
    }
    .item-implant button{
        font-weight: 400;
        font-size: 28px;
    }
    .item-implant li {
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
    .item-implant li p{
        display: none;
    }
    .item-implant li.inactive {
        transform: scale(0.7); /* Réduit la taille des éléments inactifs */
        opacity: 0.5; /* Rend les éléments inactifs moins opaques */
    }

    .item-implant li.active {
        transform: scale(1); /* Taille normale de l'élément actif */
    }
    .implant-container-inferior{
        display: block;
    }
    .display-xl{
        display: none;
    }
    .display-xs{
        display: flex;
        width: 90%;
        margin: 5%;
        border-radius: 10px;
    }
    .part-2,
    .part-1{
        width: 100%;
    }
    .spectroscopie{
        width: 90%;
        margin-left: 5%;
    }
    
    .margin5{
        width: 90%;
        margin: 5%;
    }
    .capteurs{
        margin-top: 50px;
    }
    .description{
        margin-top: 50px;
    }
    .background-capteurs{
        background-color: white;
    }
    .image-implant-container{
        margin-top: 50px;
    }
    .image-implant-container img{
        width: 80%;
    }
    .tilte-part{
        text-align: center;
    }
    .information-implant {
        width: 90%;
        margin: 5%;
    }

    .button-position{
        display: flex; 
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    
    .list-capteurs {
        width: 100%;
    }
    
}

#delta1{
    background-color: rgb(97, 0, 255);
}
#delta2{
    background-color: rgb(0, 114, 255);
}
#delta3{
    background-color: rgb(173, 255, 47);
}
#delta4{
    background-color: rgb(255, 38, 0);
}
#delta5{
    background-color: rgb(185, 44, 19);
}
#delta6{
    background-color: rgb(122, 0, 0);
}
#delta7{
    background-color: rgb(0, 0, 0);
}

</style>
